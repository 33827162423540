<template>
  <div class="edit-registrant-rate">
    <fd-form ref="regRateform" @submit="$emit('submit', form)">
      <banner class="bg-blue-100 mb-2" :dismissable="false">
        <p class="fg-grey-900">Please confirm your registrant rate (0 - 100)</p>
        <p class="fg-grey-900">
          The remaining of the share will be available to Specialist.
        </p>
      </banner>
      <fd-input
        v-model="form.registrantRate"
        class="col-12 mb-2"
        label="Registrant Rate (%)"
        name="registrantRate"
        type="number"
        :validators="[
          validator.required,
          (val) => validator.minValue(val, 0),
          (val) => validator.maxValue(val, 100)
        ]"
        @keydown.enter.native.prevent="$refs.regRateform.submit"
      >
      </fd-input>
      <div class="d-flex justify-content-end mt-3">
        <fd-button @click="$emit('close')" class="mr-2"> Cancel </fd-button>
        <fd-button type="submit" class="main">
          {{ submitButtonLabel }}
        </fd-button>
      </div>
    </fd-form>
  </div>
</template>

<script>
import {
  required,
  minValue,
  maxValue
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

export default {
  components: {
    Banner: () => import("@/components/GlobalComponents/Banner")
  },
  mixins: [],
  props: {
    payload: {
      type: Object,
      default: () => ({
        registrantRate: 0
      })
    },
    property: {
      type: Object
    },
    submitButtonLabel: {
      type: String,
      default: "Save"
    }
  },
  data: function () {
    return {
      validator: {
        required,
        minValue,
        maxValue
      }
    };
  },
  computed: {
    form: {
      get() {
        return this.payload;
      },
      set(value) {
        this.$emit("update:payload", { ...this.payload, value });
      }
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {},
  methods: {}
};
</script>

<style lang="scss"></style>
